<template>
  <add-tenant-wrapper
    ref="wrapper"
    type-label="DBET"
    :listing-id.sync="listingId"
    @property-fetched="property = $event"
  >
    <template #form>
      <dbet-contract-info-section
        ref="contractInfo"
        :contract-category.sync="contractCategory"
        :contract-note.sync="contractNote"
        @contract-changed="setInitialContract"
      />

      <bg-divider class="add-tenant__divider" />

      <add-tenant-information-section
        ref="tenantInformation"
        :initial-data="initialTenantData"
        :is-readonly="isDisableTenantInformationSection"
      />

      <bg-divider class="add-tenant__divider" />

      <dbet-payment-section
        ref="payment"
        :initial-data="initialRentData"
        :disabled="isDisablePaymentSection"
        :listing-id="listingPropertyId"
        :rent-price="dbetTotalRentPrice"
        :rent-type-options="rentTypeOptions"
        @room-changed="selectedRoom = $event"
        @rent-type-changed="rentType = $event"
      />

      <bg-divider class="add-tenant__divider" />

      <add-tenant-additional-fee
        v-if="listingPropertyId"
        :additional-fees="listingAdditionalFees"
        :loading="isFetchingAdditionalFees"
        :property-id="propertyId"
        :listing-id="listingPropertyId"
        @change="additionalPrices = $event"
      />

      <bg-button
        variant="primary"
        size="lg"
        class="mt-32"
        @click="handleSaveButtonClicked"
        >Simpan</bg-button
      >
    </template>

    <add-tenant-confirmation-modal
      v-if="dbetData"
      v-model="isShowConfirmationModal"
      payment-method="full"
      :room-price="roomPrice"
      :additional-prices="dbetData.additional_costs"
      :tenant-name="dbetData.name"
      :phone-number="dbetData.phone_number"
      :due-date="getDueDate(dbetData)"
      :room-name="selectedRoomName"
      @confirm="submitDbet"
    />

    <loading-overlay :value="isSubmitting || isFetchingListingPrices" />
  </add-tenant-wrapper>
</template>

<script>
import { dateFormatterToSend, dayjs } from 'Utils/formatter';
import {
  convertMamipayRentType,
  RENT_TYPE_MAMIPAY_ALIAS,
} from '@admin/pages/RoomAllotmentAddTenant/constants/BookingData';
import mamipayTenantAPI from '@admin_endpoints/mamipay/tenant';

import { DBET_CONTRACT_CATEGORY } from '@admin/constants/dbetContractCategory';

import { BgDivider, BgButton } from 'bangul-vue';
import LoadingOverlay from '@molecules/LoadingOverlay';

import AddTenantWrapper from '../../components/AddTenantWrapper';
import AddTenantInformationSection from '../../components/AddTenantInformationSection';
import AddTenantConfirmationModal from '../../components/AddTenantConfirmationModal';
import AddTenantAdditionalFee from '../../components/AddTenantAdditionalFee';

import DbetContractInfoSection from './components/DbetContractInfoSection';
import DbetPaymentSection from './components/DbetPaymentSection';

import mixinAddTenantDataProvider from '../../mixins/addTenantDataProvider';
import { getCheckoutDateEstimation } from '../../utils/checkoutDate';

export default {
  name: 'AddTenantDbet',

  components: {
    BgDivider,
    BgButton,
    LoadingOverlay,
    AddTenantWrapper,
    AddTenantInformationSection,
    AddTenantAdditionalFee,
    AddTenantConfirmationModal,
    DbetPaymentSection,
    DbetContractInfoSection,
  },

  mixins: [mixinAddTenantDataProvider],

  data() {
    return {
      dbetData: null,
      isShowConfirmationModal: false,
      isSubmitting: false,
      contractCategory: '',
      contractNote: '',
      initialContract: null,
    };
  },

  computed: {
    initialTenantData() {
      const tenantData = this.initialContract?.tenant || {};

      return {
        userId: tenantData?.user_id || 0,
        phoneNumber: tenantData?.phone_number || '',
        tenantName: tenantData?.name || '',
        email: tenantData?.email || '',
        gender: tenantData?.gender || '',
        job: tenantData?.job || '',
      };
    },
    initialRentData() {
      if (!this.isValidPricesData) {
        return {};
      }

      return {
        rentType: convertMamipayRentType(this.initialContract?.rent_type),
        rentDuration: this.initialContract?.rent_duration || 0,
        selectedRoomId: this.initialContract?.room?.unit?.id || 0,
        startDate:
          this.initialContract?.checkin_date &&
          dayjs(this.initialContract?.checkin_date).isValid()
            ? dayjs(this.initialContract?.checkin_date).toDate()
            : null,
      };
    },
    selectedCategoryData() {
      return DBET_CONTRACT_CATEGORY[this.contractCategory];
    },
    isDisableTenantInformationSection() {
      return (
        !this.contractCategory ||
        !!this.selectedCategoryData?.tenantInfoDisabled
      );
    },
    isDisablePaymentSection() {
      return (
        !this.isValidPricesData ||
        !this.contractCategory ||
        (!!this.selectedCategoryData?.contractIdRequired &&
          !this.initialContract)
      );
    },
    dbetTotalRentPrice() {
      return this.sumPrices([this.roomPrice, ...this.additionalPrices]);
    },
  },

  methods: {
    setInitialContract(contract) {
      this.initialContract =
        contract?.status === 'terminated' ? contract : null;
    },
    getDueDate(data) {
      const dueDate = getCheckoutDateEstimation({
        checkinDate: data.start_date,
        rentCountType: data.rent_type,
        duration: 1,
      });

      if (dueDate) {
        return dateFormatterToSend(dueDate);
      }

      return '';
    },
    getAllDbetData() {
      const { tenantInformation, payment } = this.$refs;

      const tenantData = tenantInformation.getValues();
      const rentData = payment.getValues();

      const firstFourLetterName = tenantData.contact_name.substring(0, 4);
      const isDbetName = firstFourLetterName.toLowerCase() === 'dbet';

      const params = {
        add_tenant_from: isDbetName ? 'kissflow' : 'consultant',
        room_id: this.listingId,
        name: tenantData.contact_name,
        phone_number: tenantData.contact_phone,
        email: tenantData.contact_email,
        gender: tenantData.contact_gender,
        occupation: tenantData.contact_job,
        additional_costs: this.additionalPrices,
        amount: this.roomPrice.price,
        ...rentData,
      };

      if (this.initialContract?.id) {
        params.contract_parent_id = this.initialContract.id;
      }

      if (this.contractCategory) {
        params.category = this.contractCategory;
      }

      if (this.contractNote) {
        params.note = this.contractNote;
      }

      return params;
    },
    async handleSaveButtonClicked() {
      if (!this.$refs.wrapper?.validateForm) {
        return;
      }

      const isValid = await this.$refs.wrapper.validateForm();

      if (!isValid) {
        return;
      }

      this.dbetData = this.getAllDbetData();
      this.isShowConfirmationModal = true;
    },
    getDbetPayload() {
      const dbetPayload = this.getAllDbetData();

      return {
        ...dbetPayload,
        rent_type: RENT_TYPE_MAMIPAY_ALIAS[dbetPayload.rent_type] || '',
        additional_costs: dbetPayload.additional_costs.map(
          ({ price, label }, i) => ({
            sort_order: i,
            cost_type: 'fixed',
            field_title: label,
            field_value: price,
          })
        ),
      };
    },
    async submitDbet() {
      this.isSubmitting = true;

      try {
        const dbetPayload = this.getDbetPayload();

        const res = await mamipayTenantAPI.postAddTenant(dbetPayload);

        if (res?.data?.status) {
          const startDate = dayjs(dbetPayload.start_date);
          const firstDateInStartDateMonth = startDate.isValid()
            ? dateFormatterToSend(startDate.startOf('month'))
            : undefined;

          this.$toast.show('Penyewa dan kontrak penyewa berhasil ditambah.');

          this.$router.push({
            name: 'room-allotment-calendar-view',
            query: {
              property_id: this.$route.params.propertyId,
              start_date: firstDateInStartDateMonth,
            },
          });
        } else {
          const error =
            res?.data?.meta?.message || 'Terjadi galat, silakan coba lagi.';
          this.$toast.show(error);
        }
      } catch (error) {
        this.$toast.show('Terjadi galat, silakan coba lagi.');
        this.$error.report(error);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
